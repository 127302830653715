// CSS Imports
import '../css/style.pcss';

// NPM Imports
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.start();
